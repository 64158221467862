import React, { useState, useContext } from 'react'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ToastContext } from '../../shared/contexts'
import { useSessionStore } from '../../shared/store'
import { emailReg } from '../../shared/utils/parseUtils'
import NanoPassword from '../../shared/components/NanoPassword'
import { Button, Slide } from '@mui/material'
import { client } from '../../shared/apiClient.js'
import { sendEvent } from '../../shared/utils/analyticsUtils'
import errorMessages from '../../shared/utils/errorMessagesApi'
import i18n from '../../config/i18n'

const propTypes = {}

const defaultProps = {}

function SignIn () {
  const { t } = useTranslation()
  const [remembermeChecked, setRemembermeChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const toastContext = useContext(ToastContext)
  const [forgotPassword, setforgotPassword] = useState(false)
  const containerRef = React.useRef(null)

  const {
    handleSubmit, control
  } = useForm()
  const onSubmit = async (data) => {
    setIsLoading(true)
    const promise = forgotPassword ? client.POST('/internal/reset-password', { body: data }) : useSessionStore.getState().signin({ username: data.email, ...data }, remembermeChecked, true)
    promise.then(() => {
      if (forgotPassword) {
        toastContext.sendMessage(t('request_reset_password'))
        sendEvent('reset_my_password')
      } else {
        sendEvent('login', { method: 'email' })
      }
    }).catch((err) => {
      toastContext.sendMessage(i18n.exists(err.message) ? t(err.message) : errorMessages(t).find((error) => error.message === err.message)?.code ?? t('api_common_error'), 'error')
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <>
      <Typography variant='h1'>
        {forgotPassword ? t('forgot_password') : t('happy_to_see_you_again')}
      </Typography>
      <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate ref={containerRef}>
        <Slide
          timeout={{ enter: 200, exit: 0 }}
          direction='left'
          in={forgotPassword}
          mountOnEnter
          unmountOnExit
          container={containerRef.current}
        >
          <div>
            <Controller
              name='email'
              control={control}
              defaultValue=''
              rules={{ required: t('form_field_required_error_message'), pattern: { value: emailReg, message: t('respect_email_format') } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  margin='normal'
                  fullWidth
                  label={t('email')}
                  autoComplete='email'
                  type='email'
                  {...field}
                  sx={{ minWidth: 330 }}
                  autoFocus
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </div>
        </Slide>
        <Slide
          appear={false}
          timeout={{ enter: 200, exit: 0 }}
          direction='right'
          in={!forgotPassword}
          mountOnEnter
          unmountOnExit
          container={containerRef.current}
        >
          <div>
            <Controller
              name='email'
              control={control}
              defaultValue=''
              rules={{ required: t('form_field_required_error_message'), pattern: { value: emailReg, message: t('respect_email_format') } }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  margin='normal'
                  fullWidth
                  label={t('email')}
                  id='email'
                  autoComplete='email'
                  type='email'
                  sx={{ minWidth: 330 }}
                  {...field}
                  autoFocus
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name='password'
              control={control}
              defaultValue=''
              rules={{ required: t('form_field_required_error_message') }}
              render={({ field, fieldState: { error } }) => (
                <NanoPassword
                  {...field}
                  id='password'
                  label={t('signin_text_field_password_label')}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </div>
        </Slide>
        <Grid container direction='column' gap={1} py={1}>
          <Button variant='text' sx={{ alignSelf: 'end' }} onClick={() => setforgotPassword(!forgotPassword)}>
            {forgotPassword ? t('signin_go_back_to_signin') : t('forgot_password')}
          </Button>
          {!forgotPassword && (
            <FormControlLabel
              control={<Checkbox id='remember-me' value='remember' />}
              checked={remembermeChecked}
              onChange={(_, checked) => setRemembermeChecked(checked)}
              label={t('signin_remember_me')}
              htmlFor='remember-me'
            />
          )}
        </Grid>

        <LoadingButton
          loading={isLoading}
          type='submit'
          fullWidth
        >
          {forgotPassword ? t('ask_new_password') : t('signin_submit_button')}
        </LoadingButton>
      </Box>
    </>
  )
}

SignIn.defaultProps = defaultProps
SignIn.propTypes = propTypes

export default SignIn
