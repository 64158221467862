import { Box, CircularProgress, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import NanoPaper from '../../../shared/components/NanoPaper'
import { dateMediumWithoutYearTimeAndDay } from '../../../shared/utils/dateUtils'
import { formatTimeTickLabel } from './timeTicks'

const Loader = () => (
  <Box sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }}
  >
    <CircularProgress
      size={50}
      thickness={2}
      sx={{
        margin: 'auto'
      }}
    />
  </Box>
)

export default function DeviceDailyConsumptionChart ({ graphData, isLoading, capaMax }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const capaMaxLength = capaMax.toString().length

  return (
    <NanoPaper sx={{ height: '300px' }}>
      <ResponsiveContainer width='100%' height='100%'>
        {isLoading
          ? <Loader />
          : (
            <BarChart
              data={graphData}
              margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
            >
              <CartesianGrid vertical={false} strokeDasharray='4 1 0' />
              <XAxis
                axisLine={false}
                dataKey='timestamp'
                domain={['dataMin', 'dataMax']}
                name='Time'
                tickLine={false}
                type='number'
                padding='no-gap'
                minTickGap={100}
                scale='time'
                dy={12} // vertical padding between axis and labels
                tickFormatter={formatTimeTickLabel}
              />
              <YAxis
                dataKey='value'
                tickFormatter={(value) => t('number_workspace_filling_unit', { value })}
                axisLine={false}
                width={capaMaxLength >= 5 ? 75 : 50} // adapt width to the number of digits in the capa max
                tickLine={false}
              />
              <Tooltip wrapperStyle={{ outline: 'none' }} formatter={(value) => [t('number_workspace_filling_unit', { value })]} labelFormatter={(value) => `${dateMediumWithoutYearTimeAndDay(value)}`} />
              <Bar fill={theme.palette.graph.main} dataKey={(data) => Math.round(data.value * 1000) / 1000} background={{ fill: theme.palette.grey.main }} />
            </BarChart>
            )}
      </ResponsiveContainer>

    </NanoPaper>
  )
}
